import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { dropBoolean, dropTipoPerfil } from 'utils/drops';
import { EntidadesContext } from 'contexts/EntidadesContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Aprovações = ({
  drops,
  onSubmit,
  onDelete,
  entidade = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'EntidadeAprovacao';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      entidade_id: entidade?.id,
      forma_pagto_id: item?.forma_pagto_id || null,
      tipo: item?.tipo || null,
      dias_nao_compra: item?.dias_nao_compra || '',
      valormax: item?.valormax || '',
      descontomax: item?.descontomax || '',
      prazomax: item?.prazomax || '',
      valorpend: item?.valorpend || '',
      vlratraso: item?.vlratraso || '',
      diasatraso: item?.diasatraso || '',
      qtdatraso: item?.qtdatraso || '',
      bloqueado: item?.bloqueado || null,
      analisa_id: item?.analisa_id || null,
      aprova_id: item?.aprova_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(EntidadesContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="forma_pagto_id"
            control={control}
            label="Forma de Pagamento"
            options={drops?.FormaDePagamento?.filter(
              (f) => f?.modulo !== 'COMPRAS' && f?.modulo !== 'CHEQUES'
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="tipo"
            control={control}
            label="Perfil"
            options={dropTipoPerfil?.filter((f) =>
              ['CLIENTE', 'FORNECEDOR']?.includes(f?.value)
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="dias_nao_compra"
            control={control}
            label="Dias sem Compra"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="valorpend"
            control={control}
            label="Valor Pendente"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask name="valormax" control={control} label="Valor Máximo" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="descontomax"
            control={control}
            label="Desconto Máximo"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="prazomax"
            control={control}
            label="Prazo Máximo"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InputMask
            name="vlratraso"
            control={control}
            label="Valor em Atraso"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="diasatraso"
            control={control}
            label="Dias em Atraso"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            name="qtdatraso"
            control={control}
            label="Quantidade em Atraso"
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="bloqueado"
            control={control}
            label="Bloqueado"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="analisa_id"
            control={control}
            label="Responsável pela Análise"
            options={drops?.Users}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="aprova_id"
            control={control}
            label="Responsável pela Aprovação"
            options={drops?.Users}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Aprovacoes: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Aprovações" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => openDialog(<Modal />, 'Adicionar Aprovação')}
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!entidade?.AprovacaoComercials?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Forma de Pagamento</TableCell>
                    <TableCell>Perfil</TableCell>
                    <TableCell>Responsável por Aprovação</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entidade?.AprovacaoComercials?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.FormaPagto?.descricao}</TableCell>
                      <TableCell>{item?.tipo}</TableCell>
                      <TableCell>{item?.User?.name}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Aprovação'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Aprovações;
