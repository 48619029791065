import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { ProdutosContext } from 'contexts/ProdutosContext';
import { useDialog } from 'components/Modals';
import { dropBoolean } from 'utils/drops';
import Card from 'components/Card';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import VirtualDrop from 'components/VirtualDrop';
import TableContainer from 'components/TableContainer';
import styles from '../styles';

const Substitutos = ({
  drops,
  onSubmit,
  onDelete,
  produto = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();
  const rotina = 'ProdutoSubstituto';

  const Modal = ({ item }) => {
    const defaultValues = {
      id: item?.id,
      produto_id: produto?.id,
      substituto_id: item?.substituto_id || null,
      ativo: item?.ativo || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(ProdutosContext);

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" align="center">
            Informações Gerais
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <VirtualDrop
            name="substituto_id"
            control={control}
            label="Produto Substituto"
            options={drops?.Produto}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Dropdown
            name="ativo"
            control={control}
            label="Ativo"
            options={dropBoolean}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => onSubmit({ Substitutos: [data] }))}
            loading={postLoading}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Card title="Produtos Substitutos" style={styles?.card}>
      <Grid container spacing={2}>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                openDialog(<Modal />, 'Adicionar Produto Substituto')
              }
            >
              Adicionar
            </Button>
          </Grid>
        )}
        {!!produto?.ProdutoSubstitutos?.length && (
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Produto</TableCell>
                    <TableCell>Ativo</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produto?.ProdutoSubstitutos?.map((item) => (
                    <TableRow key={item?.id?.toString()}>
                      <TableCell>{item?.substituto}</TableCell>
                      <TableCell>{item?.ativo}</TableCell>
                      <TableCell align="center">
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              openDialog(
                                <Modal item={item} />,
                                'Editar Produto Substituto'
                              )
                            }
                          >
                            <Edit />
                          </IconButton>
                        )}
                        {!disableActions && (
                          <IconButton
                            size="small"
                            onClick={() => onDelete({ rotina, id: item?.id })}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Substitutos;
