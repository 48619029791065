import React from 'react';
import Loader from 'components/Loader';
import Produto from './Produto';
import Descrições from './Descrições';
import Especificação from './Especificação';
import Midias from './Midias';
import Registros from './Registros';
import Similares from './Similares';
import Relacionados from './Relacionados';
import Substitutos from './Substitutos';
import Variações from './Variações';
import Integrações from './Integrações';
import Estrutura from './Estrutura';
import Processos from './Processos';
import Custos from './Custos';

const Tabs = ({ tab, getLoading, ...props }) => {
  if (getLoading) {
    return <Loader />;
  }
  switch (tab) {
    case 0:
      return <Produto {...props} />;
    case 1:
      return (
        <>
          <Descrições {...props} />
          <Especificação {...props} />
        </>
      );
    case 2:
      return <Midias {...props} />;
    case 3:
      return <Registros {...props} />;
    case 4:
      return <Similares {...props} />;
    case 5:
      return <Relacionados {...props} />;
    case 6:
      return <Substitutos {...props} />;
    case 7:
      return <Variações {...props} />;
    case 8:
      return <Integrações {...props} />;
    case 9:
      return (
        <>
          <Estrutura {...props} />
          <Custos {...props} />
        </>
      );
    case 10:
      return (
        <>
          <Processos {...props} />
          <Custos {...props} />
        </>
      );
    default:
      return null;
  }
};

export default Tabs;
