import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from '../styles';

const Especificação = ({
  loading,
  onSubmit,
  onDelete,
  produto = {},
  disableActions,
}) => {
  const rotina = 'ProdutoDescricao';
  const item = produto?.ProdutoDescricaos?.find(
    (d) => d?.tipo === 'ESPECIFICACAO'
  );

  const defaultValues = {
    id: item?.id,
    produto_id: produto?.id,
    descricao: item?.descricao || '',
    tipo: item?.tipo || 'ESPECIFICACAO',
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const onConfirm = (data) => {
    if (Boolean(data?.descricao)) {
      onSubmit({ Descricoes: [data] });
    } else {
      onDelete({ rotina, id: data?.id });
    }
  };

  return (
    <Card title="Especificação" style={styles?.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            name="descricao"
            control={control}
            label="Especificação"
            multiline
            rows={20}
          />
        </Grid>
        {!disableActions && (
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              loading={loading}
              onClick={handleSubmit(onConfirm)}
            >
              Salvar
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default Especificação;
